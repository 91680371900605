import React from 'react'

import Link from 'src/components/link/index'

import styles from './index.module.scss'

const Prices = () => {
	const send = (name) => {
		if (`gtag` in window) {
			window.gtag(`event`, `click`, {
				event_category: `formlink`,
				event_label: name,
				value: 1,
			})
		}
	}

	return (
		<div className={styles.prices}>
			<ul className={styles.pricelist}>
				<li>
					<span className={styles.thefit}>WE ARE THE FIT</span>
					<span className={styles.gymName}>都立大学店</span>
					<span className={styles.monthlyPrice}>
						<span>¥7,980</span> / 月<small>（税込 ¥8,778）</small>
					</span>
					<Link to="/gyms/toritsudai/" className={styles.button1}>
						詳細を見る
					</Link>
					<Link
						to="https://entry.thefit.jp/join/000101/"
						className={styles.button2}
						onClick={() => send(`toritsudai`)}
					>
						入会はこちら
					</Link>
				</li>
				<li>
					<span className={styles.thefit}>WE ARE THE FIT</span>
					<span className={styles.gymName}>江古田店</span>
					<span className={styles.monthlyPrice}>
						<span>¥7,980</span> / 月<small>（税込 ¥8,778）</small>
					</span>
					<Link to="/gyms/ekoda/" className={styles.button1}>
						詳細を見る
					</Link>
					<Link
						to="https://entry.thefit.jp/join/000102/"
						className={styles.button2}
						onClick={() => send(`ekoda`)}
					>
						入会はこちら
					</Link>
				</li>

				<li>
					<span className={styles.thefit}>WE ARE THE FIT</span>
					<span className={styles.gymName}>用賀店</span>
					<span className={styles.monthlyPrice}>
						<span>¥7,980</span> / 月<small>（税込 ¥8,778）</small>
					</span>
					<Link to="/gyms/yoga/" className={styles.button1}>
						詳細を見る
					</Link>
					<Link to="https://thefit.jp/news/join" className={styles.button2} onClick={() => send(`yoga`)}>
						入会はこちら
					</Link>
				</li>
			</ul>
		</div>
	)
}

export default Prices
